import Head from "next/head"
import Link from "next/link"
import { useTranslations } from "use-intl"
import { fetchTranslations } from "utils"

export default function Custom404() {
  const t = useTranslations("Shared.NotFound")

  return (
    <>
      <Head>
        <title>{t("meta.title")} | SeasonalJobs.dol.gov</title>
        <meta name="description" content={t("meta.description")} />
      </Head>
      <div className="grid-container items-center flex flex-col py-20">
        <h1 className="text-5xl font-light">{t("oops")}</h1>
        <div className="items-center flex flex-col">
          <p className="text-center mt-6">{t("text")}</p>
          <Link href={t("link")} passHref>
            <button
              type="button"
              className="usa-button usa-button--outline mt-6"
            >
              {t("link_text")}
            </button>
          </Link>
        </div>
      </div>
    </>
  )
}

export async function getStaticProps({ locale }) {
  const shared = await fetchTranslations({ locale, title: "Shared" })

  return {
    props: {
      messages: {
        ...shared,
      },
    },
  }
}
